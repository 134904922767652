import './UserProfile.css';

import React from 'react';
import { Col, Modal, ModalBody, Row } from 'react-bootstrap';
import _ from 'underscore';

import ChangeExamContainer from '../../components/ChangeExam/components/ChangeExamContainer';
import {
  getCycles,
  getDutyStates,
  getRanks,
  getSizeShirtTypes,
  getSubcsriptionTypes,
} from '../../utilities/getItemsHelper';
import addAbilityToOpenModal from '../../utilities/modalHOC';
import Card from '../Card/Card';
import CheckBoxList from '../CheckBoxList/CheckBoxList';
import ChangeStripeId from '../Modals/ChangeStripeId/ChangeStripeId';
import ConfirmationPassword from '../Modals/ConfirmationPassword/ConfirmationPassword';
import ConfirmDeletion from '../Modals/ConfirmDeletion/ConfirmDeletion';
import ResetPassword from '../Modals/ResetPassword/ResetPassword';
import SimpleConfirmation from '../Modals/SimpleConfirmation/SimpleConfirmation';
import Spinner from '../Spinner/Spinner';
import CardRow from './CardRow';

const OCCUPATIONAL_SUBSCTIPTION = 'PMK + Occupational';
const PMK_ONLY_SUBSCTIPTION = 'PMK only';
const NO_USER_SUBSCTIPTION = 'No active subscription';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideRequest: false,
      showPracticeExamModal: false,
      practiceExam: null,
      resetExamConfirmation: false,
      username: false,
      getUserVerifcation: true,
      isBlockUserModalOpen: false,
    };
  }

  componentWillMount() {
    this.props.loadRates();
    const query = `rank=${this.props.profile.rank}&dutyState=${this.props.profile.dutyState}&userId=${this.props.profile.id}`;
    this.props.loadCycles(query);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.restartPracticeExamSuccess) {
      this.props.setRestartPracticeExamSuccess(false);
      this.openPracticeExamModal(false, null);
      this.props.loadPracticeExams(
        this.props.profile ? this.props.profile.id : null,
      );
    }
    if (nextProps.userVerification && this.state.getUserVerifcation) {
      this.props.getUserVerification(nextProps.userVerification.user);
      this.setState({
        getUserVerifcation: false,
      });
    }
  }

  openPracticeExamModal = (value, pe) => {
    this.setState({
      showPracticeExamModal: value,
      practiceExam: pe,
      resetExamConfirmation: false,
    });
  };

  openToggleActiveModal = () => {
    const { profile } = this.props;

    this.props.openModal({
      modalTitle: `${
        profile && profile.active ? 'Disabling' : 'Enabling'
      } a user`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() =>
            this.props.onToggleActive(profile.id, profile.active)
          }
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to ${
                profile && profile.active ? 'disable' : 'enable'
              } `}
              <strong>
                {profile.name} {profile.lastName}
              </strong>
              ?
            </div>
          }
        />
      ),
    });
  };

  openDeleteUserModal = () => {
    const { profile } = this.props;

    this.props.openModal({
      modalTitle: 'Deleting a user',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.onDelete(profile.id)}
          message={`You are about to delete the user ${profile.name} ${profile.lastName}.`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  openResetUserQuestionsModal = () => {
    const { profile } = this.props;

    this.props.openModal({
      modalTitle: 'Reset user questions',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.onResetUserQuestions(profile.id)}
          message="You are about to reset the user questions?"
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  resetPasswordModal = () => {
    this.props.openModal({
      modalTitle: 'Reseting a user password',
      modalContent: (
        <ResetPassword
          onSubmit={(fields) =>
            this.props.onResetPassword(this.props.profile.id, fields)
          }
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  // Nuevo - confirm unlocked user modal - reference: openToggleActiveModal
  unlockUserModal = () => {
    const { profile } = this.props;

    this.props.openModal({
      modalTitle: 'Unlock user',
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.props.onUnlockUser(profile.id)}
          onCancel={this.props.closeModal}
          text={
            <div>
              Are you sure you want to unlock
              <strong>
                {profile.name} {profile.lastName}
              </strong>
              ?
            </div>
          }
        />
      ),
    });
  };

  blockUserModal = () => {
    const { profile } = this.props;
    this.props.openModal({
      modalTitle: profile.isBlocked ? 'Unblock user' : 'Block user',
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.props.blockUser(profile.id, !profile.isBlocked)}
          onCancel={this.props.closeModal}
          text={
            <div>
              Are you sure you want to{' '}
              {profile.isBlocked ? 'unblock ' : 'block '}
              <strong>
                {profile.name} {profile.lastName}
              </strong>
              ?
            </div>
          }
        />
      ),
    });
  };

  openChangeStripeIdModal = () => {
    this.props.openModal({
      modalTitle: `Change stripe id for ${this.props.profile.email}`,
      modalContent: (
        <ChangeStripeId
          onSubmit={(fields) => this.props.updateUser(fields)}
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  openChangeExamModal = () => {
    this.props.openModal({
      modalTitle: `Change exam for ${this.props.profile.email}`,
      modalContent: (
        <ChangeExamContainer
          onSubmit={(fields) => this.props.updateUser(fields)}
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  openModal = (content, title, className, user, action) => {
    this.props.openModal({
      modalContent: content,
      modalTitle: title,
      modalClassName: className,
      user,
      action,
    });
  };

  CheckBoxListChange = (id) => {
    this.props.changeRole(this.props.profile, id);
  };

  itemsForCheckboxList = (roles, profile) => {
    const items = [{ name: 'Super admin', checked: profile.isSuperAdmin }];
    const itemsAux = roles.map((role) => {
      role.checked = !!(
        _.find(profile.roles, { id: role.id }) && !items[0].checked
      );
      role.disabled = !!items[0].checked;
      return role;
    });
    return items.concat(itemsAux);
  };

  renderThirdInput = (forAdmin, roles, profile) => {
    if (forAdmin) {
      return (
        <div className="subtopics-list">
          <div className="subtopics-list-title">Roles</div>
          <CheckBoxList
            items={this.itemsForCheckboxList(roles, profile)}
            onChange={this.CheckBoxListChange}
          />
        </div>
      );
    }
  };

  buttons = (forAdmin, profile, roles) => (
    <div className="buttonsContainer">
      <Row>
        <Col xs={4} md={4}>
          <button
            className="btn btn-sm btn-primary resetPwd"
            onClick={this.resetPasswordModal}
          >
            Reset password
          </button>
        </Col>
        <Col xs={8} md={8}>
          <button
            className="btn btn-sm btn-primary disableUser"
            onClick={this.openToggleActiveModal}
          >
            {profile && profile.active ? 'Disable user' : 'Enable user'}
          </button>
        </Col>
      </Row>
      <Row>
        <Col xs={4} md={4}>
          {this.renderThirdInput(forAdmin, roles, profile)}
        </Col>
        <Col xs={8} md={8}>
          <button
            className="btn btn-sm btn-primary deleteUser"
            onClick={this.openDeleteUserModal}
          >
            Delete User
          </button>
        </Col>
      </Row>
      {!forAdmin && (
        <Row>
          <Col xs={4} md={4}>
            <button
              className="btn btn-sm btn-primary changeStripeIdBtn"
              onClick={() => this.openChangeStripeIdModal(profile)}
            >
              Change stripe id
            </button>
          </Col>
        </Row>
      )}
    </div>
  );

  hasPermission = (name, generalPermissions) => {
    if (!generalPermissions) return false;
    return !!generalPermissions.find((permission) => permission === name);
  };

  evaluatePermission = (name, generalPermissions) => {
    switch (name) {
      case 'stripeId':
        return this.hasPermission('Students.EditStripeId', generalPermissions);

      case 'name':
        return this.hasPermission(
          'Students.ProfileDetails',
          generalPermissions,
        );

      case 'lastName':
        return this.hasPermission(
          'Students.ProfileDetails',
          generalPermissions,
        );

      case 'username':
        return this.hasPermission(
          'Students.ProfileDetails',
          generalPermissions,
        );

      case 'email':
        return this.hasPermission(
          'Students.ProfileDetails',
          generalPermissions,
        );

      case 'password':
        return this.hasPermission(
          'Students.ChangePassword',
          generalPermissions,
        );

      case 'rate':
        return this.hasPermission(
          'Students.EditRatingExam',
          generalPermissions,
        );

      case 'dutyState':
        return this.hasPermission(
          'Students.EditRatingExam',
          generalPermissions,
        );

      case 'rank':
        return this.hasPermission(
          'Students.EditRatingExam',
          generalPermissions,
        );

      case 'cycleSelected':
        return this.hasPermission(
          'Students.EditRatingExam',
          generalPermissions,
        );
      case 'userVerificationYourText':
        return this.hasPermission(
          'Students.UpdateAddressUserVerification',
          generalPermissions,
        );
      case 'userVerificationShirtSize':
        return this.hasPermission(
          'Students.UpdateShirtSizeUserVerification',
          generalPermissions,
        );
      case 'userVerificationEmail':
        return this.hasPermission(
          'Students.UpdateEmailUserVerification',
          generalPermissions,
        );
      case 'userVerificationLastName':
        return this.hasPermission(
          'Students.UpdateLastNameUserVerification',
          generalPermissions,
        );
      case 'userVerificationFirstName':
        return this.hasPermission(
          'Students.UpdateFirstNameUserVerification',
          generalPermissions,
        );

      default:
        return false;
    }
  };

  updateUserProperties = (user, isRate) => {
    if (isRate && user.rate) {
      const selectedRate = this.props.rates.find((i) => i.id === user.rate);
      if (selectedRate && selectedRate.password) {
        this.onShowRatePasswordModal(user, selectedRate.password);
        return;
      }
    }
    this.props.updateUser(user, user.username);
    this.props.updateCycle();
  };

  updateUserVerification = (update) => {
    this.props.setUserVerification(this.props.userVerification.user, update);
    this.setState({ getUserVerifcation: true });
  };

  onShowRatePasswordModal = (user, ratePassword) => {
    this.props.openModal({
      modalContent: (
        <ConfirmationPassword
          onSubmit={(password) => {
            if (ratePassword !== password) {
              this.props.onShowNotification('Error wrong password');
              this.props.closeModal();
              return;
            }
            user.ratePassword = password;
            this.props.updateUser(user);
          }}
          onCancel={this.props.closeModal}
          title="Please type the rate password "
          submitBtnText="Save"
        />
      ),
      modalClassName:
        'modal-create-chapter grey-modal-body manual-red-component',
    });
  };

  cardRowUserVerification = (
    user = this.props.loggedUser,
    generalPermissions = this.props.generalPermissions,
    nameForValue = '',
    name,
    value,
    noEditable = false,
    isPassword = false,
    items = [],
    load = () => {},
    select = false,
    isRate = false,
  ) => (
    <CardRow
      valueName={name}
      value={value}
      noEditable={noEditable}
      updateUser={this.updateUserVerification}
      userVerification={this.props.userVerification}
      isPassword={isPassword}
      items={items}
      load={load}
      select={select}
      isRate={isRate}
      nameForValue={nameForValue}
      textArea={name === 'Address User Verification'}
      editable={
        user.isSuperAdmin
          ? true
          : this.evaluatePermission(nameForValue, generalPermissions)
      }
    />
  );

  cardRow = (
    user = this.props.loggedUser,
    generalPermissions = this.props.generalPermissions,
    nameForValue = '',
    name,
    value,
    noEditable = false,
    isPassword = false,
    items = [],
    load = () => {},
    select = false,
    isRate = false,
  ) => (
    <CardRow
      valueName={name}
      value={value}
      updateUser={this.updateUserProperties}
      noEditable={noEditable}
      profile={this.props.profile}
      isPassword={isPassword}
      items={items}
      load={load}
      select={select}
      isRate={isRate}
      nameForValue={nameForValue}
      editable={
        user.isSuperAdmin
          ? true
          : this.evaluatePermission(nameForValue, generalPermissions)
      }
    />
  );

  getNameForRank = (rank) => {
    switch (rank) {
      case 'E4':
        return 'E-4';

      case 'E5':
        return 'E-5';
      case 'E6':
        return 'E-6';

      default:
        return 'E-7';
    }
  };

  updateSubscription = (sub) => {
    const subscription = {
      id: this.props.userSubscription.id,
      isOccupational: sub.type === OCCUPATIONAL_SUBSCTIPTION,
    };
    this.props.updateUserSubscription(subscription);
  };

  getPracticeExamsContent = (practiceExams) => {
    if (practiceExams.length === 0) {
      return (
        <div className="contents cardRowContainer row">
          <div className="col-lg-4 col-md-12 col-sm-12 pr-0 title">
            Practice Exams
          </div>
          <div className="middleColumn col-lg-6 col-md-10 col-xs-10">
            <strong>No records found</strong>
          </div>
        </div>
      );
    }
    const pe = this.state.practiceExam;
    const resSum = pe ? JSON.parse(pe.resultsSummarized) : null;
    return (
      <div className="contents cardRowContainer row" style={{ width: '100%' }}>
        <div className="col-lg-4 col-md-12 col-sm-12 pr-0 title">
          Practice Exams
        </div>
        <div className="middleColumn col-lg-8 col-md-12 col-xs-12">
          {practiceExams.map((pe) => {
            const text = pe.bonus
              ? 'Bonus Practice Exam'
              : pe.extra
                ? 'Extra Practice Exam'
                : pe.free
                  ? 'Free Practice Exam'
                  : 'Paid Practice Exam';
            return (
              <div className="pe-row">
                <span className="pe-name">{text}</span>
                <button
                  className="btn btn-warning pe-button"
                  onClick={() => this.openPracticeExamModal(true, pe)}
                >
                  OPEN
                </button>
              </div>
            );
          })}
        </div>
        <Modal
          className="practice-exam-modal"
          show={this.state.showPracticeExamModal}
        >
          {pe && resSum && (
            <ModalBody className="pe-modal-body">
              <i
                className="fa fa-close close-icon"
                onClick={() => this.openPracticeExamModal(false, null)}
              />
              <div className="big-title">Practice Exam Summary</div>
              <hr />
              <div className="detail-row">
                <span className="detail-title">Correct Answers</span>
                <span className="detail-value">
                  {resSum.correctAnswers.value}/{resSum.totalQuestions}
                </span>
                <span className="detail-points">
                  {`+${resSum.correctAnswers.points} points`}
                </span>
              </div>
              <div className="detail-row">
                <span className="detail-title">Accuracy</span>
                <span className="detail-value">{resSum.accuracy.value}%</span>
                <span className="detail-points">
                  {`+${resSum.accuracy.points} points`}
                </span>
              </div>
              <div className="detail-row">
                <span className="detail-title">Time Elapsed</span>
                <span className="detail-value">{resSum.timeElapsed.value}</span>
                <span className="detail-points">
                  {`+${resSum.timeElapsed.points} points`}
                </span>
              </div>
              <hr />
              {!this.state.resetExamConfirmation && (
                <div className="pe-modal-footer">
                  <button
                    className="reset-exam-button btn btn-primary ml-auto mr-0"
                    onClick={() => this.resetExam(true)}
                  >
                    RESET PRACTICE EXAM
                  </button>
                </div>
              )}
              {this.state.resetExamConfirmation && (
                <div className="pe-modal-footer">
                  <span className="confirmation-text">
                    Are you sure you want to reset this practice exam?
                  </span>
                  {!this.props.restartPracticeExamWaiting && (
                    <button
                      className="reset-exam-button btn btn-primary ml-auto mr-0"
                      onClick={() => this.resetExam(false)}
                    >
                      YES, CONTINUE
                    </button>
                  )}
                  {this.props.restartPracticeExamWaiting && <Spinner />}
                </div>
              )}
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  };

  resetExam = (value) => {
    if (value) {
      this.setState({ resetExamConfirmation: true });
    } else {
      this.props.restartPracticeExam(
        this.state.practiceExam.id,
        this.props.profile.id,
      );
    }
  };

  getCycleFromUser = () => {
    const auxCycles = [...this.props.cycles];
    let cycle = null;
    if (this.props.cycles && this.props.profile) {
      auxCycles.map((item) => {
        if (item.id === this.props.profile.cycleSelected) {
          cycle = item;
        }
      });
      if (cycle === null) {
        auxCycles.map((item) => {
          if (item.isActive === true) {
            cycle = item;
          }
        });
      }
      if (cycle !== null) {
        return cycle.title;
      }
    }
  };

  cardContent = (generalPermissions, user) => {
    const DutyStates = getDutyStates();
    const Ranks = getRanks();
    DutyStates.splice(0, 1);
    Ranks.splice(0, 1);
    const hasSubscription = this.props.userSubscription.id;
    const { practiceExams, practiceExamsLoading, couponUser, profile } =
      this.props;
    const couponName =
      (profile && profile.coupon) || (couponUser && couponUser.name) || '';
    return (
      <Row className="cardContentWrapper">
        {this.cardRow(
          user,
          generalPermissions,
          'isActive',
          'Status',
          this.props.profile
            ? this.props.profile.active
              ? 'Enabled'
              : 'Disabled'
            : '',
          true,
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'isBlocked',
          'Block Status',
          this.props.profile && this.props.profile.isBlocked
            ? 'Blocked'
            : 'Not Blocked',
          true,
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'id',
          'User ID',
          this.props.profile ? this.props.profile.id : '',
          true,
        )}
        {this.cardRow(user, generalPermissions, 'team', 'Team', '')}
        {this.cardRow(
          user,
          generalPermissions,
          'name',
          'First name',
          this.props.profile ? this.props.profile.name : '',
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'lastName',
          'Last name',
          this.props.profile ? this.props.profile.lastName : '',
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'username',
          'Username',
          this.props.profile ? this.props.profile.username : '',
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'email',
          'Email',
          this.props.profile ? this.props.profile.email : '',
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'password',
          'Password',
          '00000000',
          false,
          true,
        )}
        {this.cardRow(
          user,
          generalPermissions,
          'phoneNumber',
          'Phone Number',
          this.props.profile
            ? this.props.profile.phoneNumber
              ? `${this.props.profile.countryCode} ${this.props.profile.phoneNumber}`
              : 'No phone number added'
            : '',
          true,
        )}
        <div className="separatorLine" />
        <div className="separatorLineMargin">
          {/* RATE SELECT */}
          {this.cardRow(
            user,
            generalPermissions,
            'rate',
            'Rate',
            this.props.profile.rate
              ? {
                  id: this.props.profile.rate.id,
                  name: this.props.profile.rate.shortname,
                }
              : '',
            false,
            false,
            this.props.rates.filter((rate) => rate.name !== 'PMK'),
            () => {},
            true,
            true,
          )}
        </div>
        {/* EXAM PAYGRADE SELECT */}
        {this.cardRow(
          user,
          generalPermissions,
          'rank',
          'Exam paygrade',
          this.props.profile.rank ? this.props.profile.rank : '',
          false,
          false,
          Ranks,
          null,
          true,
        )}
        {/* EXAM TYPE SELECT */}
        {this.cardRow(
          user,
          generalPermissions,
          'dutyState',
          'Exam type',
          this.props.profile.dutyState ? this.props.profile.dutyState : '',
          !!(this.props.profile.rate && this.props.profile.rate.isPmkOnly),
          false,
          DutyStates,
          null,
          true,
        )}
        {/* CYCLE SELECT */}
        {this.cardRow(
          user,
          generalPermissions,
          'cycleSelected',
          'Cycle',
          this.props.cycles ? this.getCycleFromUser() : '',
          false,
          false,
          this.props.cycles ? getCycles(this.props.cycles) : null,
          null,
          true,
        )}
        <div className="separatorLine" />
        {/* PRACTICE EXAMS SECTION */}
        {practiceExamsLoading && (
          <div
            className="user-practice-exams-container"
            style={{ width: '100%' }}
          >
            <Spinner />
          </div>
        )}
        {!practiceExamsLoading && (
          <div
            className="user-practice-exams-container"
            style={{ width: '100%' }}
          >
            {this.getPracticeExamsContent(practiceExams)}
          </div>
        )}
        {this.props.profile.stripeId && (
          <>
            <div className="separatorLine" />
            <div className="separatorLineMargin">
              {this.cardRow(
                user,
                generalPermissions,
                'stripeId',
                'Stripe ID',
                this.props.profile.stripeId ? (
                  <a
                    href={`https://dashboard.stripe.com/customers/${this.props.profile.stripeId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stripe Profile
                  </a>
                ) : (
                  ''
                ),
              )}
              {this.cardRow(
                user,
                generalPermissions,
                'referralLink',
                'Referral Link',
                this.props.profile ? this.props.profile.referralLink : '',
                true,
              )}
              {!this.props.userSubscription.type ? (
                <Spinner />
              ) : (
                <Row className="cardRowContainer" style={{ width: '100%' }}>
                  <Col xs={12} md={12} lg={4} style={{ paddingRight: '0px' }}>
                    <div className="valueName">Subscription</div>
                  </Col>
                  <Col xs={12} md={12} lg={8} style={{ paddingRight: '0px' }}>
                    <div className="value">
                      {this.props.userSubscription.type}
                    </div>
                  </Col>
                </Row>
              )}
              {this.props.userSubscription.type &&
                this.props.userSubscription.type !== NO_USER_SUBSCTIPTION && (
                  <CardRow
                    valueName="Subscription Type"
                    nameForValue="type"
                    value={
                      !hasSubscription
                        ? 'The user has no subcription'
                        : this.props.userSubscription.isOccupational
                          ? OCCUPATIONAL_SUBSCTIPTION
                          : PMK_ONLY_SUBSCTIPTION
                    }
                    updateUser={this.updateSubscription}
                    profile={{}}
                    items={getSubcsriptionTypes()}
                    load={() => {}}
                    select
                    isRate={false}
                    editable={hasSubscription}
                  />
                )}
              {this.props.userSubscription.type &&
                this.props.userSubscription.type !== NO_USER_SUBSCTIPTION && (
                  <CardRow
                    valueName="Coupon"
                    nameForValue="coupon"
                    value={couponName}
                    updateUser={(value) =>
                      this.props.updateUser({
                        id: this.props.profile.id,
                        ...value,
                      })
                    }
                    profile={{}}
                    items={this.coupons()}
                    load={() => {}}
                    select
                    isRate={false}
                    editable={hasSubscription}
                  />
                )}
            </div>
          </>
        )}
        {/* User Verification */}
        <div className="separatorLine" />
        <div className="separatorLineMargin">
          {!this.props.userSubscription.type ? (
            <Spinner />
          ) : (
            <div>
              {this.props.userVerification &&
              this.props.userVerification.yourText ? (
                <div>
                  {this.cardRowUserVerification(
                    user,
                    generalPermissions,
                    'yourText',
                    'Address User Verification',
                    this.props.userVerification &&
                      this.props.userVerification.yourText
                      ? this.props.userVerification.yourText
                      : '',
                  )}
                  {this.props.userVerification &&
                    this.props.userVerification.tShirtSize && (
                      <CardRow
                        valueName="T-Shirt Size"
                        nameForValue="tShirtSize"
                        value={
                          this.props.userVerification.tShirtSize
                            ? this.props.userVerification.tShirtSize
                            : ['S', 'M', 'L', 'XL', 'XXL']
                        }
                        updateUser={this.updateUserVerification}
                        userVerification={this.props.userVerification}
                        profile={{}}
                        items={getSizeShirtTypes()}
                        load={() => {}}
                        select
                        isRate={false}
                        editable={
                          this.props.userVerification
                            ? this.props.userVerification.tShirtSize
                            : ''
                        }
                      />
                    )}

                  {this.cardRowUserVerification(
                    user,
                    generalPermissions,
                    'email',
                    'Email',
                    this.props.userVerification &&
                      this.props.userVerification.email
                      ? this.props.userVerification.email
                      : '',
                  )}

                  {this.cardRowUserVerification(
                    user,
                    generalPermissions,
                    'lastName',
                    'Last Name',
                    this.props.userVerification &&
                      this.props.userVerification.lastName
                      ? this.props.userVerification.lastName
                      : '',
                  )}
                  {this.cardRowUserVerification(
                    user,
                    generalPermissions,
                    'firstName',
                    'First Name',
                    this.props.userVerification &&
                      this.props.userVerification.firstName
                      ? this.props.userVerification.firstName
                      : '',
                  )}
                </div>
              ) : (
                <div className="notCycles">
                  <span>User Verification information not available</span>
                </div>
              )}
            </div>
          )}
        </div>
      </Row>
    );
  };

  loginAs = (platformType) => {
    this.props.loginAs(
      this.props.profile.id,
      platformType,
      this.props.userAdmin.id,
    );
  };

  coupons = () =>
    this.props.applicableCoupons.map((c) => ({
      id: c.name,
      name: `${c.name} - ${c.description}`,
    }));

  getOptions = (generalPermissions, user) => {
    let options = [
      {
        name: 'Login as student',
        event: () => this.loginAs('web'),
      },
      {
        name: 'Login as student in app',
        event: () => this.loginAs('app'),
      },
      {
        name: this.props.profile.active ? 'Disable student' : 'Enable student',
        event: this.openToggleActiveModal,
      },
      {
        name: 'Delete student',
        event: this.openDeleteUserModal,
      },
      {
        name: 'Force password update',
        event: this.props.forcePasswordUpdate,
      },
      {
        name: 'Reset questions',
        event: this.openResetUserQuestionsModal,
      },
      {
        name: 'Unlock user',
        event: this.unlockUserModal,
      },
      ...(this.props.profile && this.props.profile.isBlocked
        ? [
            {
              name: 'Unblock user',
              event: this.blockUserModal,
            },
          ]
        : [
            {
              name: 'Block user',
              event: this.blockUserModal,
            },
          ]),
    ];
    if (user && user.isSuperAdmin) return options;
    if (
      !generalPermissions.find(
        (permission) => permission === 'Students.LoginAs',
      )
    ) {
      options = options.filter(
        (option) =>
          option.name !== 'Login as student' &&
          option.name !== 'Login as student in app',
      );
    }
    if (
      !generalPermissions.find(
        (permission) => permission === 'Students.Disable',
      )
    )
      options = options.filter(
        (option) =>
          option.name !== 'Disable student' && option.name !== 'Enable student',
      );
    if (
      !generalPermissions.find((permission) => permission === 'Students.Delete')
    )
      options = options.filter(
        (option) =>
          option.name !== 'Disable student' || option.name !== 'Enable student',
      );
    if (
      !generalPermissions.find((permission) => permission === 'Students.Delete')
    )
      options = options.filter((option) => option.name !== 'Delete student');
    if (
      !generalPermissions.find(
        (permission) => permission === 'Students.EditRatingExam',
      )
    )
      options = options.filter((option) => option.name !== 'Change exam');
    if (
      !generalPermissions.find(
        (permission) => permission === 'Students.ChangePassword',
      )
    )
      options = options.filter(
        (option) => option.name !== 'Force password update',
      );
    if (
      !generalPermissions.find(
        (permission) => permission === 'Students.UpdateAddressUserVerification',
      )
    );
    if (
      !generalPermissions.find(
        (permission) =>
          permission === 'Students.UpdateShirtSizeUserVerification',
      )
    );
    if (
      !generalPermissions.find(
        (permission) => permission === 'Students.UpdateEmailUserVerification',
      )
    );
    if (
      !generalPermissions.find(
        (permission) =>
          permission === 'Students.UpdateLastNameUserVerification',
      )
    );
    if (
      !generalPermissions.find(
        (permission) =>
          permission === 'Students.UpdateFirstNameUserVerification',
      )
    );

    return options;
  };

  render() {
    const { generalPermissions, loggedUser } = this.props;
    return (
      <div className="userCardsContainer">
        <Card
          title="Student"
          content={this.cardContent(generalPermissions, loggedUser)}
          options={this.getOptions(generalPermissions, loggedUser)}
        />
      </div>
    );
  }
}

export default addAbilityToOpenModal(UserProfile);
