import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function ImageSizeNotificationModal({ isOpen, handleToggleModal }) {
  const closeBtn = (
    <button className="modal__close-button" onClick={handleToggleModal}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={isOpen} toggle={handleToggleModal}>
      <ModalHeader toggle={handleToggleModal} close={closeBtn}>
        IMAGE SIZE WARNING
      </ModalHeader>
      <ModalBody>Uploaded images should be at least 75KB.</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleToggleModal}>
          UNDERSTOOD
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ImageSizeNotificationModal;
