import './Bibliographies.css';

import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';
import _ from 'underscore';

import CheckBoxList from '../../../commonComponents/CheckBoxList/CheckBoxList';
import DropDown from '../../../commonComponents/DropDown/DropDown';
import BibliographyLogs from '../../../commonComponents/Modals/BibliographyLogs/BibliographyLogs';
import CreateChapter from '../../../commonComponents/Modals/CreateChapter/CreateChapter';
import CreateReference from '../../../commonComponents/Modals/CreateReference/CreateReference';
import SimpleConfirmation from '../../../commonComponents/Modals/SimpleConfirmation/SimpleConfirmation';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import SubHeader from '../../../commonComponents/SubHeader/SubHeader';
import NumberUtils from '../../../lib/NumberUtils';
import { hasPagePermissionRates } from '../../../utilities/FilterLinksForTabs';
import { getRatingExam } from '../../../utilities/getItemsHelper';
import CyclesContainer from '../../Cycles/components/CyclesContainer';
import BibliographyList from './BibliographyList/BibliographyList';

const CYCLE_TYPES = {
  Regular: {
    E4: 'A',
    E5: 'A',
    E6: 'A',
    E7: 'B',
  },
  Substitute: {
    E4: 'A',
    E5: 'A',
    E6: 'A',
    E7: 'B',
  },
  Reserve: {
    E4: 'C',
    E5: 'C',
    E6: 'C',
    E7: 'D',
  },
};

export default class NewBibliographies extends React.Component {
  state = {
    selectAllReferences: false,
    selectAllSubtopics: false,
    items: [],
    itemsReferences: [],
    isViewSelected: false,
    filename: 'bibliography.csv',
    reportFilename: 'report.csv',
    substituteLinked: false,
    reserveLinked: false,
    highlightBook: this.props.references.highlightBook,
  };

  componentDidMount() {
    this.props.loadCycles();
    this.props.loadRates();
    this.props.setRatingExamQuestions();
    this.props.setIsUndoChecked(true);
    this.props.setIsRevertChecked(true);
  }

  componentWillReceiveProps({
    topicsImmutable,
    referencesImmutable,
    checkedSubtopics,
    checkedReferences,
    waiting,
    topics,
    references,
    user,
    generalRolesPermissions,
    rate,
    activeTab,
    ratingExamQuestions,
  }) {
    const questions = ratingExamQuestions
      ? ratingExamQuestions.find(
          (a) =>
            a.rank === this.props.rank &&
            a.dutyState === this.props.dutyState &&
            a.rate === rate.id,
        )
      : null;
    if (questions) {
      questions.linkedRatingExam &&
      questions.linkedRatingExam.indexOf('Substitute') !== -1
        ? this.setState({ substituteLinked: true })
        : this.setState({ substituteLinked: false });
      questions.linkedRatingExam &&
      questions.linkedRatingExam.indexOf('Reserve') !== -1
        ? this.setState({ reserveLinked: true })
        : this.setState({ reserveLinked: false });
    } else {
      this.setState({ substituteLinked: false });
    }
    if (this.props.topicsImmutable !== topicsImmutable) {
      this.convertTopicsFromServer(topics, checkedSubtopics);
    }

    if (this.props.referencesImmutable !== referencesImmutable) {
      this.convertReferencesFromServer(references, checkedReferences);
    }

    if (this.props.checkedSubtopics !== checkedSubtopics) {
      this.convertTopicsFromServer(topics, checkedSubtopics);
    }

    if (this.props.checkedReferences !== checkedReferences) {
      this.convertReferencesFromServer(references, checkedReferences);
    }

    if (references !== this.state.itemsReferences) {
      this.setState({
        itemsReferences: this.convertReferencesFromServer(
          references,
          checkedReferences,
          true,
        ),
      });
    }

    if (waiting !== this.props.waiting && !waiting) {
      this.props.closeModal();
    }

    if (
      rate.id &&
      (rate.id !== this.props.rate.id || this.props.activeTab !== activeTab)
    ) {
      // Removing unnecessary calls to the API
      // TODO Remove this
      // this.loadContent(rate.id, activeTab);
    }
  }

  componentDidUpdate() {
    if (this.props.bibliographyJson) {
      document.getElementById('download').click();
      this.props.clearBibJson();
      this.setState({ filename: 'bibliography.csv' });
    }
    if (this.props.rateChapterQuestionsReport) {
      document.getElementById('downloadReport').click();
      this.props.clearBibJson();
      this.setState({ reportFilename: 'report.csv' });
    }
  }

  loadContent = (rateId, activeTab = this.props.activeTab) => {
    if (activeTab === 'References') {
      this.props.loadReferences(rateId, 'rate');
    } else {
      // Removing unnecessary calls to the API
      // TODO Remove this
      // this.props.loadReferences(rateId, 'rate');
      this.props.loadTopics(rateId);
    }
  };

  convertSubtopicsFromServer = (topic, checkedSubtopicsIds = []) => {
    if (topic.subtopics) {
      return topic.subtopics.map((subtopic) => ({
        id: subtopic.id,
        name: subtopic.name,
        checked: !!checkedSubtopicsIds.find(
          (checkedSubtopicId) => subtopic.id === checkedSubtopicId,
        ),
        chapters: subtopic.chapters,
        questionQty: subtopic.questionQty,
      }));
    }
    return [];
  };

  convertChaptersFromServer = (reference, checkedReferencesIds = []) => {
    let chapters = [];
    if (reference.chapters && reference.chapters.length > 0) {
      const checkedReferences = reference.chapters.filter(
        (chapter) => checkedReferencesIds.indexOf(chapter.id) !== -1,
      );
      chapters = reference.chapters.map((chapter) => ({
        id: chapter.id,
        name: chapter.name,
        questionCount: chapter.questionCount,
        checked: !!checkedReferences.find(
          (chapterAux) => chapterAux.id === chapter.id,
        ),
        enabled: chapter.enabled,
        description: chapter.description,
      }));
    } else if (!reference.actAsParent) {
      chapters.push({
        name: reference.name,
        id: reference.id,
        checked: false,
        description: reference.description,
      });
    }
    return chapters;
  };

  convertTopicsFromServer = (
    topics,
    checkedSubtopicsIds = this.props.checkedSubtopics,
    isReturn = false,
  ) => {
    let items = [];
    if (topics && topics.length > 0) {
      items = topics.map((topic) => ({
        name: topic.name,
        subtopics: this.convertSubtopicsFromServer(topic, checkedSubtopicsIds),
      }));
    }
    if (this.state.reverseSubtopics) {
      items = _.sortBy(items, 'name').reverse();
    } else {
      items = _.sortBy(items, 'name');
    }
    if (isReturn) {
      return items;
    }
    this.setState({ items });
  };

  convertReferencesFromServer = (
    references,
    checkedReferencesIds = this.props.checkedReferences,
    isReturn = false,
  ) => {
    let itemsReferences = [];

    if (references) {
      itemsReferences = references.map((reference) => ({
        name: reference.name,
        actAsParent: reference.actAsParent,
        highlightBook: reference.highlightBook,
        chapters: this.convertChaptersFromServer(
          reference,
          checkedReferencesIds,
        ),
        description: reference.description,
      }));
    }
    if (this.state.reverseRefs) {
      itemsReferences = _.sortBy(itemsReferences, 'name').reverse();
    } else {
      itemsReferences = _.sortBy(itemsReferences, 'name');
    }
    if (isReturn) {
      return itemsReferences;
    }
    this.setState({ itemsReferences });
  };

  filterCycles = (rank = this.props.rank, dutyState = this.props.dutyState) => {
    const type = CYCLE_TYPES[dutyState][rank];
    return this.props.cycles.filter((cycle) => cycle.type === type);
  };

  updatebib = (bib) => {
    const fromRegular = true;
    const bibliography = bib;
    bibliography.fromRegular = fromRegular;
    const { ratingExamQuestions = [], rate } = this.props;
    const questions = ratingExamQuestions.find(
      (a) =>
        a.rank === bibliography.rank &&
        a.dutyState === bibliography.dutyState &&
        a.rate === rate.id,
    );
    if (questions) {
      if (!questions.linkedRatingExam) {
        bibliography.whichUpdate = 'Regular';
        this.props.submitBibliography(bibliography);
      } else {
        const question = JSON.parse(questions.linkedRatingExam);
        question.forEach((element) => {
          if (element.Substitute) {
            bibliography.whichUpdate =
              bibliography.whichUpdate && bibliography.whichUpdate === 'Reserve'
                ? (bibliography.whichUpdate = 'All')
                : (bibliography.whichUpdate = 'Substitute');
          } else if (element.Reserve) {
            bibliography.whichUpdate =
              bibliography.whichUpdate &&
              bibliography.whichUpdate === 'Substitute'
                ? (bibliography.whichUpdate = 'All')
                : (bibliography.whichUpdate = 'Reserve');
          }
        });
        this.props.submitBibliography(bibliography);
      }
    } else {
      this.props.submitBibliography(bibliography);
    }
  };

  handleUndo = () => {
    this.props.openModal({
      modalTitle: `Undo bibliography update`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => {
            this.props.setIsUndoChecked(true);
            this.props.setIsRevertChecked(false);
            this.updateUndo();
          }}
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to undo the bibliography update for the ${this.props.rate.shortname} -> ${this.props.rank} -> ${this.props.dutyState} exam?`}
            </div>
          }
          greenButton
        />
      ),
    });
  };

  handleRevert = () => {
    this.props.openModal({
      modalTitle: `Revert bibliography update`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => {
            this.props.setIsUndoChecked(false);
            this.props.setIsRevertChecked(true);
            this.updateUndo();
          }}
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to revert the bibliography update for the ${this.props.rate.shortname} -> ${this.props.rank} -> ${this.props.dutyState} exam?`}
            </div>
          }
          greenButton
        />
      ),
    });
  };

  updateUndo = () => {
    const undoState = { ...this.props.getUndoState };
    const actualState = { ...this.props.getActualState };
    const { chapters } = undoState;
    this.props.updateCheckedReferences(chapters);
    this.props.setUndoState(actualState);
    this.props.setActualState(undoState);
    this.updatebib(undoState);
  };

  handleSubmit = () => {
    const { checkedReferences, checkedSubtopics, originalCheckedReferences } =
      this.props;
    let undoState = null;
    if (this.props.activeTab === 'References' && this.props.isUndoChecked) {
      undoState = {
        rank: this.props.rank,
        dutyState: this.props.dutyState,
        rate: this.props.rate.id,
        cycle: this.props.cycle.id,
        chapters: originalCheckedReferences,
      };
    }
    const bibliography = {
      rank: this.props.rank,
      dutyState: this.props.dutyState,
      rate: this.props.rate.id,
      cycle: this.props.cycle.id,
    };
    if (this.props.activeTab === 'Subtopics') {
      bibliography.subtopics = checkedSubtopics;
    } else {
      bibliography.chapters = checkedReferences;
    }
    this.props.openModal({
      modalTitle: `Submitting bibliography`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => {
            if (this.props.activeTab === 'References') {
              if (this.props.getUndoState && this.props.getActualState) {
                const actualState = { ...this.props.getActualState };
                this.props.setUndoState(actualState);
                this.props.setActualState(bibliography);
              } else {
                this.props.setUndoState(undoState);
                this.props.setActualState(bibliography);
              }
            }
            this.props.setIsRevertChecked(true);
            this.props.setIsUndoChecked(false);
            this.updatebib(bibliography);
          }}
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to update the bibliography for the ${this.props.rate.shortname} -> ${this.props.rank} -> ${this.props.dutyState} exam?`}
            </div>
          }
          greenButton
        />
      ),
    });
  };

  handleCreateReference = () => {
    this.props.openModal({
      modalContent: (
        <CreateReference
          onSubmit={(fields) =>
            this.props.createReference(fields, this.props.rate.id)
          }
          onCancel={this.props.closeModal}
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  handleCreateChapter = (referenceName) => {
    this.props.openModal({
      modalContent: (
        <CreateChapter
          onSubmit={(fields) => this.props.createChapter(fields)}
          onCancel={this.props.closeModal}
          references={this.props.references.filter(
            (ref) => ref.actAsParent && ref.name === referenceName,
          )}
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  handleViewLogs = () => {
    const { rate, rank, dutyState, closeModal } = this.props;
    this.props.openModal({
      modalContent: (
        <BibliographyLogs
          ratingExam={getRatingExam(rate, rank)}
          dutyState={dutyState}
          onClose={closeModal}
        />
      ),
      modalClassName: 'modal-create-chapter log-modal',
      className: '',
    });
  };

  handleDownloadCSV = () => {
    this.props.loadRateBibliographyJson(this.props.rate.id);
    this.setState({
      filename: `${this.props.rate.shortname}-${moment().format(
        'MMM',
      )}${moment().format('DD')}-${moment().format('YYYY')}.csv`,
    });
  };

  handleDownloadReport = () => {
    this.props.loadRateChapterQuestionsReport(this.props.rate.id);
    this.setState({
      filename: `${this.props.rate.shortname}-${moment().format(
        'MMM',
      )}${moment().format('DD')}-${moment().format('YYYY')}-report.csv`,
    });
  };

  handleSubsLink = () => {
    const { checkedReferences, checkedSubtopics } = this.props;
    const bibliography = {
      rank: this.props.rank,
      dutyState: this.props.dutyState,
      rate: this.props.rate.id,
      cycle: this.props.cycle.id,
      fromRegular: false,
    };
    const subs = this.state.substituteLinked ? 'Unlink from' : 'Link to';
    if (this.props.activeTab === 'Subtopics') {
      bibliography.subtopics = checkedSubtopics;
    } else {
      bibliography.chapters = checkedReferences;
      if (subs === 'Link to') {
        bibliography.subtopics = checkedSubtopics;
      }
    }
    if (bibliography.dutyState === 'Regular') {
      bibliography.substituteLinked = this.state.substituteLinked === false;
    }
    this.props.openModal({
      modalTitle: `Link Substitute information`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.props.submitBibliography(bibliography)}
          onCancel={this.props.closeModal}
          text={<div>{`Are you sure you want to ${subs}substitute?`}</div>}
          greenButton
        />
      ),
    });
  };

  handleReserveLink = () => {
    const { checkedReferences, checkedSubtopics } = this.props;
    const bibliography = {
      rank: this.props.rank,
      dutyState: this.props.dutyState,
      rate: this.props.rate.id,
      cycle: this.props.cycle.id,
      fromRegular: false,
    };
    const reser = this.state.reserveLinked ? 'Unlink from' : 'Link to';
    if (this.props.activeTab === 'Subtopics') {
      bibliography.subtopics = checkedSubtopics;
    } else {
      bibliography.chapters = checkedReferences;
    }
    if (bibliography.dutyState === 'Regular') {
      bibliography.reserveLinked = this.state.reserveLinked === false;
    }
    this.props.openModal({
      modalTitle: `Link Substitute information`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.props.submitBibliography(bibliography)}
          onCancel={this.props.closeModal}
          text={<div>{`Are you sure you want to ${reser}reserve?`}</div>}
          greenButton
        />
      ),
    });
  };

  getOptions = () => {
    const options = [];
    if (
      this.props.user.isSuperAdmin ||
      hasPagePermissionRates('Books', this.props.generalRolesPermissions)
    ) {
      options.push({
        name: 'Create Reference',
        event: this.handleCreateReference,
      });
    }
    options.push({
      name: 'View logs',
      event: this.handleViewLogs,
    });
    options.push({
      name: 'Download CSV',
      event: this.handleDownloadCSV,
    });
    options.push({
      name: 'Q manually set report',
      event: this.handleDownloadReport,
    });
    if (
      this.props.dutyState === 'Regular' &&
      this.props.activeTab === 'References'
    ) {
      const subs = this.state.substituteLinked ? 'Unlink from' : 'Link to';
      options.push({
        name: `${subs} Substitute`,
        event: this.handleSubsLink,
      });
      /* Discoment when link to reserve functionality it's done.
      let reser = this.state.reserveLinked ? 'Unlink from' : 'Link to'
      options.push({
        name: `${reser}Reserve`,
        event: this.handleReserveLink,
      }); */
    }
    return options;
  };

  getRatingExamButtons = () => {
    const filteredCycles = this.filterCycles();
    const dropDownOptions = this.getOptions();
    return (
      <div className="ratingButtonsContainer">
        <div className="ratingExamButtons" onClick={this.toggleViewSelected}>
          {this.state.isViewSelected ? 'View All' : 'View Selected'}
        </div>
        <div className="ratingExamButtons" onClick={this.onClearSelected}>
          Clear selections
        </div>
        <div className="ratingExamButtonsBlue" onClick={this.handleSubmit}>
          Save Selection
        </div>
        {!this.props.isUndoChecked && this.props.activeTab === 'References' ? (
          <div className="ratingExamButtonsBlue" onClick={this.handleUndo}>
            Undo
          </div>
        ) : null}
        {!this.props.isRevertChecked &&
        this.props.activeTab === 'References' ? (
          <div className="ratingExamButtonsBlue" onClick={this.handleRevert}>
            Revert
          </div>
        ) : null}
        <select
          className="selectContainerBibliographies"
          value={this.props.cycle ? this.props.cycle.id : ''}
          onChange={(event) => this.handleChangeCycle(event.target.value)}
        >
          {filteredCycles.length > 0 ? (
            filteredCycles.map((cycle) => (
              <option key={NumberUtils.obtainUniqueKey()} value={cycle.id}>
                {this.getCycleTitle(cycle)}
              </option>
            ))
          ) : (
            <option>No cycles created yet</option>
          )}
        </select>
        {dropDownOptions.length > 1 && (
          <div className="dotsContainer">
            <DropDown
              options={dropDownOptions}
              style={{
                position: 'absolute',
                right: '5px',
                zIndex: '5',
              }}
            />
          </div>
        )}
      </div>
    );
  };

  toggleViewSelected = () => {
    if (this.state.isViewSelected) {
      if (this.props.activeTab === 'Subtopics') {
        this.convertTopicsFromServer(
          this.props.topics,
          this.props.checkedSubtopics,
        );
      } else {
        this.convertReferencesFromServer(
          this.props.references,
          this.props.checkedReferences,
        );
      }
    } else {
      if (this.props.activeTab === 'Subtopics') {
        let items = this.state.items.map((r) => {
          r.subtopics = r.subtopics.filter((s) => s.checked);
          return r;
        });
        items = items.filter((r) => r.subtopics.length > 0);
        this.setState({ items });
      } else {
        let itemsReferences = this.state.itemsReferences.map((r) => {
          r.chapters = r.chapters.filter((s) => s.checked);
          return r;
        });
        itemsReferences = itemsReferences.filter((r) => r.chapters.length > 0);
        this.setState({ itemsReferences });
      }
    }
    this.setState({ isViewSelected: !this.state.isViewSelected });
  };

  getCycleTitle = (cycle) => {
    let title = `${cycle.title} - ${cycle.description}`;
    if (cycle.isActive) {
      title = `* ${title}`;
    }
    return title;
  };

  updateCheckedSubtopics = () => {
    if (this.state.items.length > 0) {
      let allSubtopics = [];
      const newItems = this.state.items.map((topic) => {
        topic.subtopics = topic.subtopics.map((sub) => {
          sub.checked = !this.state.selectAllSubtopics;
          return sub;
        });
        allSubtopics = allSubtopics.concat(topic.subtopics);
        return topic;
      });

      const newCheckedValues = allSubtopics.filter((item) => item.checked);

      this.props.updateCheckedSubtopics(_.pluck(newCheckedValues, 'id'));
      this.setState({
        items: newItems,
        selectAllSubtopics: !this.state.selectAllSubtopics,
        isViewSelected: false,
      });
    }
  };

  updateCheckedReferences = () => {
    if (this.state.itemsReferences.length > 0) {
      let allChapters = [];
      const newItems = this.state.itemsReferences.map((reference) => {
        reference.chapters = reference.chapters.map((ch) => {
          ch.checked = !this.state.selectAllReferences;
          return ch;
        });
        allChapters = allChapters.concat(reference.chapters);
        return reference;
      });

      const newCheckedValues = allChapters.filter((item) => item.checked);

      this.props.updateCheckedReferences(_.pluck(newCheckedValues, 'id'));
      this.setState({
        itemsReferences: newItems,
        selectAllReferences: !this.state.selectAllReferences,
        isViewSelected: false,
      });
    }
  };

  onClearSelected = () => {
    if (this.props.activeTab === 'Subtopics') {
      this.props.updateCheckedSubtopics([]);
      this.setState({ selectAllSubtopics: false });
    } else {
      this.props.updateCheckedReferences([]);
      this.setState({ selectAllReferences: false });
    }
  };

  onChangeSelectAllSubtopics = () => {
    this.updateCheckedSubtopics();
  };

  onChangeSelectAllReferences = () => {
    this.updateCheckedReferences();
  };

  checkSubtopic = (id) => {
    let items = [];
    this.state.items.map((item) => {
      item.subtopics = item.subtopics.map((s) => {
        s.checked = s.id === id ? !s.checked : s.checked;
        return s;
      });
      items = items.concat(item.subtopics);
      return item;
    });

    const itemsIds = items
      .filter((item) => item.checked)
      .map((item) => item.id);

    this.props.updateCheckedSubtopics(itemsIds);
    this.setState({ items, isViewSelected: false });
  };

  checkTopic = (evt, id) => {
    let items = [];
    this.state.items.map((item, i) => {
      if (id === i) {
        item.subtopics = item.subtopics.map((s) => {
          s.checked = evt.target.checked; // eslint-disable-line no-self-assign
          // s.id === id ? !s.checked : s.checked;
          return s;
        });
      } else {
        item.subtopics = item.subtopics.map((s) => {
          s.checked = s.checked;
          return s;
        });
      }

      items = items.concat(item.subtopics);
      return item;
    });

    const itemsIds = items
      .filter((item) => item.checked)
      .map((item) => item.id);

    this.props.updateCheckedSubtopics(itemsIds);
    this.setState({ items, isViewSelected: false });
  };

  checkReference = (id) => {
    let itemsReferences = [];
    this.state.itemsReferences.map((item) => {
      item.chapters = item.chapters.map((s) => {
        s.checked = s.id === id ? !s.checked : s.checked;
        return s;
      });
      itemsReferences = itemsReferences.concat(item.chapters);
      return item;
    });

    const itemsReferencesIds = itemsReferences
      .filter((item) => item.checked)
      .map((item) => item.id);

    this.props.updateCheckedReferences(itemsReferencesIds);
    this.setState({ itemsReferences, isViewSelected: false });
  };

  checkReferenceGroup = (evt, id) => {
    let itemsReferences = [];
    this.state.itemsReferences.map((item, i) => {
      if (id === i) {
        item.chapters = item.chapters.map((s) => {
          s.checked = evt.target.checked; // eslint-disable-line no-self-assign
          return s;
        });
      } else {
        item.chapters = item.chapters.map((s) => {
          s.checked = s.checked;
          return s;
        });
      }
      itemsReferences = itemsReferences.concat(item.chapters);
      return item;
    });

    const itemsReferencesIds = itemsReferences
      .filter((item) => item.checked)
      .map((item) => item.id);

    this.props.updateCheckedReferences(itemsReferencesIds);
    this.setState({ itemsReferences, isViewSelected: false });
  };

  onCheckSubtopic = (id) => {
    this.checkSubtopic(parseInt(id, 10));
  };

  onCheckReference = (id) => {
    this.checkReference(parseInt(id, 10));
  };

  getRatingExamContent = () => {
    if (this.props.loadingExam || this.props.loadingGrid) {
      return <Spinner text="Loading content" />;
    }
    if (this.props.activeTab === 'Subtopics') {
      return (
        <div style={{ paddingLeft: '0', overflowY: 'scroll', height: '100%' }}>
          <div className="select-all-option">
            <label style={{ margin: 0 }}>
              <input
                ref="checkbox"
                type="checkbox"
                value="selectAllSubtopics"
                name="selectAllSubtopics"
                checked={this.state.selectAllSubtopics}
                onChange={this.onChangeSelectAllSubtopics}
              />
              <span className="checkbox-inline" style={{ marginLeft: '10px' }}>
                Select/Deselect all
              </span>
            </label>
          </div>
          <div className="subtopics-list">
            {this.state.items.map((item, i) => (
              <div key={`${item.name}_${i}`}>
                <div className="subtopicTitle">
                  <label style={{ margin: 0 }}>
                    <input
                      ref="checkbox"
                      type="checkbox"
                      value="selectTopic"
                      name="selectTopic"
                      checked={(() => {
                        let checked = item.subtopics.length > 0;
                        item.subtopics = item.subtopics.map((s) => {
                          if (!s.checked) {
                            checked = false;
                            return s;
                          }
                          return s;
                        });
                        return checked;
                      })()}
                      onChange={(evt) => {
                        this.checkTopic(evt, i);
                      }}
                    />
                    <span style={{ marginLeft: '10px' }}>{item.name}</span>
                  </label>
                </div>
                <div style={{ padding: '0px 25px', marginBottom: '10px' }}>
                  <CheckBoxList
                    items={item.subtopics.map((item) => ({
                      ...item,
                      name: (
                        <div>
                          {item.name}{' '}
                          <span className="questions-qty">
                            ({item.questionQty} questions)
                          </span>
                        </div>
                      ),
                    }))}
                    onChange={this.onCheckSubtopic}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div style={{ paddingLeft: '0', overflowY: 'scroll', height: '100%' }}>
        <div className="select-all-option">
          <label style={{ margin: 0 }}>
            <input
              ref="checkbox"
              type="checkbox"
              value="selectAllReferences"
              name="selectAllReferences"
              checked={this.state.selectAllReferences}
              onChange={this.onChangeSelectAllReferences}
            />
            <span className="checkbox-inline" style={{ marginLeft: '10px' }}>
              Select/Deselect all
            </span>
          </label>
        </div>
        <div className="subtopics-list">
          {this.state.itemsReferences.map((item, i) => {
            if (!item.actAsParent) {
              return (
                <div key={`${item.name}_${i}`} className="subtopicTitle">
                  <CheckBoxList
                    items={item.chapters.map((chapter) => ({
                      ...chapter,
                      name: (
                        <div>
                          {`${item.name} - ${item.description}`}{' '}
                          <span className="questions-qty">
                            ({chapter.questionCount} questions)
                          </span>
                          {!chapter.enabled ? ' (Disabled)' : ''}
                        </div>
                      ),
                    }))}
                    onChange={this.onCheckReference}
                  />
                </div>
              );
            }
            const referenceName = item.name;
            return (
              <div key={`${item.name}_${i}`}>
                <div className="subtopicTitle" style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', width: '90%' }}>
                    <label style={{ margin: 0 }}>
                      <input
                        ref="checkbox"
                        type="checkbox"
                        value="selectReference"
                        name="selectReference"
                        checked={(() => {
                          let checked = item.chapters.length > 0;
                          item.chapters = item.chapters.map((s) => {
                            if (!s.checked) {
                              checked = false;
                              return s;
                            }
                            return s;
                          });
                          return checked;
                        })()}
                        onChange={(evt) => {
                          this.checkReferenceGroup(evt, i);
                        }}
                      />
                      <span style={{ marginLeft: '10px' }}>
                        <span
                          style={{
                            color:
                              item.highlightBook && this.props.user.isSuperAdmin
                                ? 'red'
                                : 'black',
                          }}
                        >
                          {item.name}
                        </span>{' '}
                        - {item.description}
                      </span>
                    </label>
                  </div>
                  {this.props.user.isSuperAdmin ||
                  hasPagePermissionRates(
                    'Books',
                    this.props.generalRolesPermissions,
                  ) ? (
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Create Chapter"
                    >
                      <i
                        className="fa fa-plus fa-lg"
                        onClick={() => this.handleCreateChapter(referenceName)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ padding: '0px 25px', marginBottom: '10px' }}>
                  <CheckBoxList
                    items={item.chapters.map((item) => ({
                      ...item,
                      name: (
                        <div>
                          {`${item.name} - ${item.description}`}{' '}
                          <span className="questions-qty">
                            ({item.questionCount} questions)
                          </span>
                          {!item.enabled ? ' (Disabled)' : ''}
                        </div>
                      ),
                    }))}
                    onChange={this.onCheckReference}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  handleChangeCycle = (cycleId) => {
    cycleId = parseInt(cycleId, 10);

    const cycle = this.props.cycles.find((cycle) => cycle.id === cycleId);
    this.props.updateCycle(cycle);

    this.props.loadExam(
      this.props.dutyState,
      this.props.rank,
      this.props.rate.id,
      cycleId,
    );
  };

  onCanListUpdate = (rate, rank, dutyState) => {
    if (this.wereThereChanges()) {
      this.props.openModal({
        modalTitle: `Changes not saved`,
        modalContent: (
          <SimpleConfirmation
            onConfirm={() => {
              this.onListUpdate(rate, rank, dutyState);
              this.props.closeModal();
            }}
            onCancel={this.props.closeModal}
            text={<div>Changes not saved. Are you sure you want to leave?</div>}
            greenButton
          />
        ),
      });
    } else {
      this.onListUpdate(rate, rank, dutyState);
    }
  };

  wereThereChanges = (tab) => {
    tab =
      tab ||
      (this.props.activeTab === 'References' ? 'Subtopics' : 'References');
    let hasChanges = false;
    if (tab === 'References') {
      const originalTopics = this.props.originalCheckedSubtopics;
      const checkedTopics = this.props.checkedSubtopics;
      hasChanges = !_.isEqual(
        originalTopics.sort((a, b) => a > b),
        checkedTopics.sort((a, b) => a > b),
      );
    } else {
      const originalReferences = this.props.originalCheckedReferences;
      const checkedReferences = this.props.checkedReferences;
      hasChanges = !_.isEqual(
        originalReferences.sort((a, b) => a > b),
        checkedReferences.sort((a, b) => a > b),
      );
    }
    return hasChanges;
  };

  onUpdateSelectedTab = (tab) => {
    if (this.wereThereChanges(tab)) {
      this.props.openModal({
        modalTitle: `Changes not saved`,
        modalContent: (
          <SimpleConfirmation
            onConfirm={() => {
              this.props.onUpdateSelectedTab(tab);
              this.props.closeModal();
              this.props.updateCheckedSubtopics(
                this.props.originalCheckedSubtopics,
              );
              this.props.updateCheckedReferences(
                this.props.originalCheckedReferences,
              );
            }}
            onCancel={this.props.closeModal}
            text={<div>Changes not saved. Are you sure you want to leave?</div>}
            greenButton
          />
        ),
      });
    } else {
      this.props.onUpdateSelectedTab(tab);
    }
    if (this.props.rate.id) {
      this.loadContent(this.props.rate.id, tab);
    }
  };

  getContent = () => {
    if (this.props.activeListTab === 'Cycles') {
      return <CyclesContainer />;
    }
    if (!this.props.rate.id) {
      return '';
    }
    return (
      <div style={{ height: '88%' }}>
        <SubHeader
          options={['Subtopics', 'References']}
          onOptionChange={this.onUpdateSelectedTab}
          activeOption={this.props.activeTab}
        />
        {this.getRatingExamButtons()}
        {this.getRatingExamContent()}
      </div>
    );
  };

  onListUpdate = (rate, rank, dutyState) => {
    this.props.getRateRatingExamQuestions(rate, rank, dutyState);
    this.props.updateDutyState(dutyState);
    this.props.updateRank(rank);
    this.props.updateRate(rate);

    if (rate.id !== this.props.rate.id) {
      if (this.props.activeTab === 'References') {
        this.props.loadReferences(rate.id, 'rate');
      } else {
        this.props.loadTopics(rate.id);
      }
    }
    let cycle = this.filterCycles(rank, dutyState);
    cycle = cycle || [];
    cycle = cycle.filter((c) => c.isActive)[0];
    if (cycle && this.props.cycle && cycle.type !== this.props.cycle.type) {
      this.props.updateCycle(cycle);
    } else if (this.props.cycle && this.props.cycle.id) {
      cycle = this.props.cycle;
    }
    if (cycle && cycle.id) {
      this.props.loadExam(dutyState, rank, rate.id, cycle.id);
    } else {
      this.props.loadExamNoCycle();
    }
  };

  render() {
    const { bibliographyJson = '', rateChapterQuestionsReport = '' } =
      this.props;
    return (
      <div className="bibliography-container">
        <BibliographyList {...this.props} onListUpdate={this.onCanListUpdate} />
        <div style={{ paddingLeft: '0px', flex: 1 }}>{this.getContent()}</div>
        <div style={{ display: 'none' }} className="assignmentsTables">
          <CSVLink
            id="download"
            filename={this.state.filename}
            data={bibliographyJson}
          />
          <CSVLink
            id="downloadReport"
            filename={this.state.filename}
            data={rateChapterQuestionsReport}
          />
        </div>
      </div>
    );
  }
}
