import React from 'react';
import './Users.css';
import Card from '../../../commonComponents/Card/Card';
import { Row, Col } from 'react-bootstrap';
import { InputWithoutIcon } from '../../../commonComponents/Input/Input';
import { getRanks, getDutyStates } from '../../../utilities/getItemsHelper';
import { Field } from 'redux-form';
import Button from 'react-bootstrap-button-loader';

class NewStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: 'Select rate',
      rank: 'Select paygrade',
      dutyState: 'Select exam type',
      plan: 'Select plan',
      type: 'Select type',
      redBorder: {
        name: false,
        lastName: false,
        email: false,
        password: false,
        rate: false,
        rank: false,
        dutyState: false,
        stripeId: false,
        username: false,
      },
      empty: false,
    };
  }

  componentDidMount() {
    this.props.loadRates();
  }

  componentWillUnmount() {
    this.props.setNewStudentError(false);
  }

  componentWillReceiveProps({ creationResponse }) {
    if (
      this.props.creationResponse === creationResponse &&
      creationResponse !== null
    )
      return;
    this.setState({
      empty: true,
      rate: 'Select rate',
      rank: 'Select paygrade',
      dutyState: 'Select exam type',
      plan: 'Select plan',
      type: 'Select type',
    });
    // this.props.resetForm();
    if (creationResponse) {
      let _this = this;
      this.props.onSetSearch(creationResponse.email);
      this.props.toggleNew();
      setTimeout(() => {
        _this.props.setSelectedUser({ creationResponse });
        _this.props.loadUserStatistics(creationResponse.id);
      }, 500);
    }
  }

  cardRow = (
    allowEmpty,
    name,
    placeholder,
    type,
    select = false,
    options,
    selectPlaceholder,
    empty,
    message = null,
  ) => {
    return (
      <Row className="cardRowContainer" style={{ width: '100%' }}>
        <Col xs={4} md={4} lg={4}>
          <div className="valueName">
            {!allowEmpty && <span className="obligatoryField">*</span>}
            {placeholder}
          </div>
        </Col>
        <Col xs={8} md={8} lg={8}>
          {select
            ? this.renderSelect(options, selectPlaceholder, name)
            : this.renderInput(name, placeholder, type, empty, message)}
        </Col>
      </Row>
    );
  };

  renderSelect = (options, selectPlaceholder, name) => {
    if (options[0].id == 'All') {
      options[0].disabled = true;
    }
    return (
      <select
        value={this.state[name]}
        className={
          this.state.redBorder[name]
            ? 'form-control input-group mb-3 selectNewStudent redBorder'
            : 'form-control input-group mb-3 selectNewStudent '
        }
        onChange={e => {
          if (name === 'rate')
            this.setState({
              [name]: e.target.value,
              plan: 'Select plan',
              type: 'Select type',
            });
          else {
            this.setState({ [name]: e.target.value });
          }
        }}
      >
        <option value={selectPlaceholder}>{selectPlaceholder}</option>
        {options &&
          options.length !== 0 &&
          options.map(option => (
            <option
              key={'option-select-' + option.id}
              value={option.id}
              disabled={option.disabled ? true : false}
            >
              {option.shortname
                ? option.shortname + ' - ' + option.name
                : option.name}
            </option>
          ))}
      </select>
    );
  };

  renderInput = (name, placeholder, type = 'text', empty, message) => {
    return (
      <div>
        <Field
          name={name}
          type={type}
          component={InputWithoutIcon}
          placeholder={placeholder}
          className={
            this.state.redBorder[name] ||
            ((name == 'email' || name == 'stripeId') &&
              this.props.newStudentError)
              ? 'form-control redBorder'
              : 'form-control'
          }
          empty={empty}
          resetEmptyInput={() => {
            this.setState({ empty: false });
          }}
        />
        {this.state.errors && this.state.errors[name] && (
          <span className="errors">{this.state.errors[name]}</span>
        )}
        {message === 'There is no subscription for that stripeId' && (
          <span className="errors">{message}</span>
        )}
      </div>
    );
  };

  onCancel = () => {
    this.props.toggleNew();
    this.props.setNewStudentError(false);
  };

  reinitValues = () => {
    this.setState({
      redBorder: {
        name: false,
        lastName: false,
        email: false,
        password: false,
        rate: false,
        rank: false,
        dutyState: false,
        username: false,
      },
    });
  };

  validate = fields => {
    if (
      !fields.name ||
      !fields.lastName ||
      !fields.email ||
      !fields.password ||
      !fields.username ||
      !fields.stripeId ||
      this.state.rate === 'Select rate' ||
      this.state.rank === 'Select paygrade' ||
      this.state.dutyState === 'Select exam type'
    ) {
      this.setState({
        redBorder: {
          name: !fields.name,
          lastName: !fields.lastName,
          email: !fields.email,
          password: !fields.password,
          rate: this.state.rate === 'Select rate',
          rank: this.state.rank === 'Select paygrade',
          dutyState: this.state.dutyState === 'Select exam type',
          username: !fields.username,
          stripeId: !fields.stripeId,
          plan: this.state.plan === 'Select plan',
          type: this.state.type === 'Select type',
        },
      });
      return;
    }
    let newStudent = Object.assign({}, fields);
    newStudent.rate = Number(this.state.rate);
    newStudent.rank = this.state.rank;
    newStudent.dutyState = this.state.dutyState;
    newStudent.planId = Number(this.state.plan);
    newStudent.isOccupational = this.state.type !== 'pmkOnly';
    this.reinitValues();
    this.props.setMessage(null);
    this.props.setNewStudentError(false);
    this.props.createStudent(newStudent);
  };

  getSubscriptionTypes = () => {
    return [
      {
        id: 'pmkOnly',
        name: 'PMK Only',
      },
      {
        id: 'PMK + Occupational Only',
        name: 'PMK + Occupational Only',
        disabled:
          this.props.rates.length > 0 &&
          this.state.rate !== 'Select rate' &&
          this.props.rates.find(rate => rate.id === Number(this.state.rate))
            .isPmkOnly
            ? true
            : false,
      },
    ];
  };

  getPlans = () => {
    if (this.props.plans.length === 0) return [];
    return this.props.plans
      .sort(function(a, b) {
        return a.interval_count - b.interval_count;
      })
      .map(plan => {
        return {
          id: plan.id,
          name:
            plan.interval_count === 1
              ? plan.amount / 100 + ' per month'
              : plan.amount / 100 + ' per 6 months',
        };
      });
  };

  cardContent = () => {
    const { empty } = this.state;
    return (
      <Row className="cardContentWrapper newStudentWrapper">
        <form
          onSubmit={this.props.handleSubmit(fields => this.validate(fields))}
          style={{ width: '100%' }}
        >
          {this.cardRow(
            true,
            'team',
            'Select team',
            'text',
            false,
            null,
            '',
            empty,
          )}
          {this.cardRow(
            false,
            'name',
            'Fist name',
            'text',
            false,
            null,
            '',
            empty,
          )}
          {this.cardRow(
            false,
            'lastName',
            'Last name',
            'text',
            false,
            null,
            '',
            empty,
          )}
          {this.cardRow(
            false,
            'username',
            'User name',
            'text',
            false,
            null,
            '',
            empty,
          )}
          {this.cardRow(
            false,
            'email',
            'Email',
            'email',
            false,
            null,
            '',
            empty,
          )}
          {this.cardRow(
            false,
            'password',
            'password',
            'password',
            false,
            null,
            '',
            empty,
          )}
          <div className="separatorLine" />
          <div className="separatorLineMargin">
            {this.cardRow(
              false,
              'rate',
              'Rate',
              null,
              true,
              this.props.rates.filter(rate => rate.name !== 'PMK'),
              'Select rate',
            )}
          </div>
          {this.cardRow(
            false,
            'rank',
            'Exam paygrade',
            null,
            true,
            getRanks(),
            'Select paygrade',
          )}
          {this.cardRow(
            false,
            'dutyState',
            'Exam type',
            null,
            true,
            getDutyStates(),
            'Select exam type',
          )}
          <div className="separatorLine" />
          <div className="separatorLineMargin">
            {this.cardRow(
              false,
              'stripeId',
              'Stripe ID',
              'text',
              false,
              null,
              '',
              empty,
              this.props.message,
            )}
          </div>
          {this.props.newStudentError && (
            <p style={{ color: 'red' }}>
              The email is already in use or there is no subscription for that
              Stripe Id
            </p>
          )}
          <div className="buttonsContainer">
            <Button
              className="btn btn-success"
              type="submit"
              loading={this.props.loading}
            >
              Create
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={() => this.onCancel()}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Row>
    );
  };

  render() {
    return (
      <div className="newStudentContainer">
        <Card title="New student" content={this.cardContent()} />
      </div>
    );
  }
}

export default NewStudent;
