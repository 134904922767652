import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'users');
}

export function getUsers(state) {
  return getState(state).get('users');
}

export function getUserPagination(state) {
  return getState(state).get('pagination');
}

export function getActivityPagination(state) {
  return getState(state).get('activityPagination');
}

export function getFormErrors(state) {
  return getState(state).get('formErrors');
}

export function getIsWaiting(state) {
  return getState(state).get('isWaiting');
}

export function getLoading(state) {
  return getState(state).get('loading');
}

export function getSelectedUser(state) {
  return getState(state).get('selectedUser');
}
export function getIsBetaUserSelector(state) {
  return getState(state).get('isBetaUser');
}

export function getUserVerificationSelector(state) {
  return getState(state).get('userVerification');
}

export function getStatistics(state) {
  return getState(state).get('statistics');
}

export function getListNewCycles(state) {
  return getState(state).get('listNewCycles');
}

export function getDisableCycle(state) {
  return getState(state).get('disableCycle');
}

export function getCreationResponse(state) {
  return getState(state).get('creationResponse');
}

export function getMessage(state) {
  return getState(state).get('message');
}

export function getUserSubscription(state) {
  return getState(state).get('subscriptionType');
}

export function getIsNewStudent(state) {
  return getState(state).get('isNewStudent');
}

export function getLoadingInfo(state) {
  return getState(state).get('loadingInfo');
}

export function getPracticeExams(state) {
  return getState(state).get('practiceExams');
}

export function getPracticeExamsWaiting(state) {
  return getState(state).get('practiceExamsWaiting');
}

export function getRestartPracticeExamWaiting(state) {
  return getState(state).get('restartPracticeExamWaiting');
}

export function getRestartPracticeExamSuccess(state) {
  return getState(state).get('restartPracticeExamSuccess');
}

export function getNewStudentError(state) {
  return getState(state).get('newStudentError');
}

export function getNoteDoNotContact(state) {
  return getState(state).get('noteDoNotContact');
}

export function getReasonCancellation(state) {
  return getState(state).get('reasonCancellation');
}

export function getIntercomCancellationReason(state) {
  return getState(state).get('intercomCancellationReason');
}

export function getApplicableCoupons(state) {
  return getState(state).get('applicableCoupons');
}

export function geteducationalInstitutionData(state) {
  return getState(state).get('educationalInstitutionData');
}

export function getCouponUserSelector(state) {
  return getState(state).get('coupon');
}

export function getIsLoadingBlockStatusSelector(state) {
  return getState(state).get('isLoadingBlockStatus');
}

export function getIsLoadingFavoriteBlockStatus(state) {
  return getState(state).get('isLoadingFavoriteBlockStatus');
}
